var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bntech-w",attrs:{"id":"bntech-w"}},[_vm._m(0),_c('div',{staticClass:"main-w grid-contain"},[_c('van-tabs',{ref:"tabs",attrs:{"title-active-color":"#29A93E","color":"#29A93E","line-height":"0","scrollspy":"","sticky":"","id":"tabsId"},model:{value:(_vm.tabActive),callback:function ($$v) {_vm.tabActive=$$v},expression:"tabActive"}},[_c('van-tab',{attrs:{"title-class":"tab_title","name":"硬件与软件"}},[_c('div',{staticClass:"tab-title-w",attrs:{"slot":"title"},slot:"title"},[_c('div',{class:_vm.tabActive === '硬件与软件'
                ? 'tab-title--border tab-title'
                : 'tab-title'},[_vm._v("硬件与软件")])]),_c('div',{staticClass:"tab-block-1 tab-content"},[_c('div',{staticClass:"view-l"},[_c('img',{attrs:{"src":require("@/assets/image/b-c-1.png"),"alt":""}})]),_c('div',{staticClass:"view-r"},[_c('div',{staticClass:"name"},[_c('div',{staticClass:"line"}),_vm._v(" 硬件与软件")]),_c('div',{staticClass:"desc"},[_vm._v(" 基于手表、手环、血压仪、体脂称等相关设备收集用户的健康数据、配合大数据、人工智能给用户最合理的一份健康报告； ")]),_c('div',{staticClass:"desc"},[_vm._v(" 同时可以第一时间预知健康异常的用户，并及时提醒用户以及家属。同时配合百年的安全系统，第一时间预知危险信息，如最新爆发的疫情、危险交通路况、橙色天气预警等。 ")])])])]),_c('van-tab',{attrs:{"title-class":"tab_title","name":"AI人工智能"}},[_c('div',{staticClass:"tab-title-w",attrs:{"slot":"title"},slot:"title"},[_c('div',{class:_vm.tabActive === 'AI人工智能'
                ? 'tab-title--border tab-title'
                : 'tab-title'},[_vm._v("AI人工智能")])]),_c('div',{staticClass:"tab-block-1 tab-content"},[_c('div',{staticClass:"view-r"},[_c('div',{staticClass:"name"},[_c('div',{staticClass:"line"}),_vm._v("AI人工智能")]),_c('div',{staticClass:"desc"},[_vm._v(" 基于8维（环境温度、手腕温度、时间、步数、心率、血压、体脂、时间）的数据信息进行建模、配合大数据进行训练，及时的预知用户的健康状态、以及未来走势 ")])]),_c('div',{staticClass:"view-l"},[_c('img',{attrs:{"src":require("@/assets/image/b-c-2.png"),"alt":""}})])])]),_c('van-tab',{attrs:{"title-class":"tab_title","name":"专利"}},[_c('div',{staticClass:"tab-title-w",attrs:{"slot":"title"},slot:"title"},[_c('div',{class:_vm.tabActive === '专利'
                ? 'tab-title--border tab-title'
                : 'tab-title'},[_vm._v("专利")])]),_c('div',{staticClass:"tab-block-1 tab-content"},[_c('div',{staticClass:"view-l"},[_c('img',{attrs:{"src":require("@/assets/image/b-c-3.png"),"alt":""}})]),_c('div',{staticClass:"view-r"},[_c('div',{staticClass:"name"},[_c('div',{staticClass:"line"}),_vm._v("专利")]),_c('div',{staticClass:"desc"},[_vm._v(" 公司自主研发的血压测量与监测引擎，引擎运用产品内置血压示波光学芯片模组，利用光学绿光检测体循环动脉血压（即血液在血管内流动所作用于体循环的压力）。 ")]),_c('div',{staticClass:"desc"},[_c('div',[_vm._v("血压算法引擎B-pumping")]),_c('div',[_vm._v("跌倒算法引擎B-SteadyFall")]),_c('div',[_vm._v("睡眠算法引擎B-Exactsleep")])])])])]),_c('van-tab',{attrs:{"title-class":"tab_title","name":"软著"}},[_c('div',{staticClass:"tab-title-w",attrs:{"slot":"title"},slot:"title"},[_c('div',{class:_vm.tabActive === '软著'
                ? 'tab-title--border tab-title'
                : 'tab-title'},[_vm._v("软著")])]),_c('div',{staticClass:"tab-block-1 tab-content"},[_c('div',{staticClass:"view-r"},[_c('div',{staticClass:"name"},[_c('div',{staticClass:"line"}),_vm._v("软著")]),_c('div',{staticClass:"desc"},[_vm._v(" 百年医养APP（用户端）是居家医养一站式服务平台，拥有一键救援、健康体征远程全监测、远程防丢定位、健康周报等功能，并为长者提供优质、专业的居家照护全套服务。 ")]),_c('div',{staticClass:"desc"},[_vm._v(" 百年医养APP（医护端）采用先进的互联网、物联网、大数据、云计算、人工智能等技术，为全国的居家护理人员提供学习交流、培训成长、职业升级、在线接单、订单分配、团队管理、长护险护理等增值服务。 ")])]),_c('div',{staticClass:"view-l"},[_c('img',{attrs:{"src":require("@/assets/image/b-c-4.png"),"alt":""}})])])])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-w"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"t1"},[_vm._v("百年AI人工智能技术")]),_c('div',{staticClass:"t2"},[_vm._v("血压检测管理解决方案")])])])
}]

export { render, staticRenderFns }